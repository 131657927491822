import { graphql } from "gatsby";
import React from "react";

import Layout from "src/components/layout";
import SEO from "src/components/seo";

import ChooseAPlan from "src/sections/pricing/ChooseAPlan";
import FreeTrial from "src/sections/pricing/FreeTrial";

const PricingPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Pricing" />
      <FreeTrial data={data} />
      <ChooseAPlan data={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    arwing: file(relativePath: { eq: "arwing.png" }) {
      ...fluidImage
    }
    customAir: file(relativePath: { eq: "customAir.png" }) {
      ...fluidImage
    }
    customEmote: file(relativePath: { eq: "customEmote.png" }) {
      ...fluidImage
    }
    dotGrid: file(relativePath: { eq: "dotGrid.png" }) {
      ...fluidImage
    }
    interfacePanel: file(relativePath: { eq: "interfacePanel.png" }) {
      ...fluidImage
    }
    coin: file(relativePath: { eq: "goldCoin.png" }) {
      ...fluidImage
    }
  }
`;

export default PricingPage;
