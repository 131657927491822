import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import Colors from "src/styles/Colors";
import pointer from "svg/pointer.svg";

import { Label1, Paragraph1, Title1 } from "src/components/Text";
import { FluidImage } from "src/Types";
import media from "src/styles/media";

type Props = {
  data: {
    customAir: FluidImage;
    customEmote: FluidImage;
    arwing: FluidImage;
    dotGrid: FluidImage;
    interfacePanel: FluidImage;
  };
};

const FreeTrial: React.FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <Label>(Bitski User Wallet: Always Free)</Label>
      <Title>Get Started now, Choose a plan later</Title>
      <Paragraph>
        No credit card required, Bitski is free until you publish your first
        NFT.
      </Paragraph>
      <a href="https://creator.bitski.com">
        <Button>start free trial</Button>
      </a>
      <Grid>
        <Img fluid={data.dotGrid.childImageSharp.fluid} />
      </Grid>
      <Panel>
        <Img fluid={data.interfacePanel.childImageSharp.fluid} />
      </Panel>
      <CustomAir>
        <Img fluid={data.customAir.childImageSharp.fluid} />
      </CustomAir>
      <CustomEmote>
        <Img fluid={data.customEmote.childImageSharp.fluid} />
      </CustomEmote>
      <Arwing>
        <Img fluid={data.arwing.childImageSharp.fluid} />
      </Arwing>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 64.4vw;
  padding: 15.3vw 0 0 6.9vw;
  position: relative;
  overflow: hidden;
  ${media.tablet} {
    padding: 31.2vw 0 0 4.8vw;
    height: 212.6vw;
  }
`;
const Label = styled(Label1)`
  color: ${Colors.brightRed};
`;
const Title = styled(Title1)`
  color: white;
  width: 52.1vw;
  margin: 1.4vw 0 2.1vw;
  ${media.tablet} {
    width: 84.1vw;
    margin: 4.8vw 0 7.2vw;
  }
`;
const Paragraph = styled(Paragraph1)`
  color: white;
  width: 33.8vw;
  ${media.tablet} {
    width: 62.1vw;
    font-size: 3.9vw;
  }
`;

const Button = styled.button`
  border: 1px solid ${Colors.darkGrey};
  background: white;
  width: 12.1vw;
  height: 3.5vw;
  font-size: 1.2vw;
  position: relative;
  top: 3.1vw;
  cursor: url(${pointer}), auto;
  box-shadow: 0.3vw 0.3vw ${Colors.brightRed};
  ${media.tablet} {
    width: 42vw;
    height: 12.1vw;
    font-size: 4.3vw;
    box-shadow: 1.2vw 1.2vw ${Colors.brightRed};
    top: 58.9vw;
  }
`;
const Grid = styled.div`
  position: absolute;
  width: 21vw;
  top: 11vw;
  left: 79.9vw;
  ${media.tablet} {
    width: 53vw;
    top: 91.1vw;
    left: 66.4vw;
  }
`;
const Panel = styled.div`
  position: absolute;
  width: 16.5vw;
  top: 17.7vw;
  left: 84.2vw;
  ${media.tablet} {
    width: 43vw;
    top: 106.3vw;
    left: 76.6vw;
  }
`;
const CustomAir = styled.div`
  position: absolute;
  width: 24.8vw;
  left: 61.6vw;
  top: 13.6vw;
  ${media.tablet} {
    width: 56.8vw;
    top: 96.9vw;
    left: 24.9vw;
  }
`;
const CustomEmote = styled.div`
  position: absolute;
  width: 16vw;
  left: 82vw;
  top: 33.3vw;
  ${media.tablet} {
    width: 36.5vw;
    top: 141.8vw;
    left: 71.5vw;
  }
`;
const Arwing = styled.div`
  position: absolute;
  width: 15.9vw;
  top: 25.8vw;
  left: 95vw;
  ${media.tablet} {
    display: none;
  }
`;
export default FreeTrial;
