import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import { Paragraph1, Title2, Title3 } from "src/components/Text";

import Colors from "src/styles/Colors";

import { BoxOutlineButton } from "src/components/Buttons";

import { ReactComponent as ArrowSVG } from "svg/arrowRight.svg";
import { ReactComponent as GridSVG } from "svg/pricingGrid.svg";

import { FluidImage } from "src/Types";
import media from "src/styles/media";

type Props = {
  data: {
    coin: FluidImage;
  };
};

const ChooseAPlan: React.FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <Grid />
      <Title>Interested in choosing a plan?</Title>
      <Line />
      <Plans>
        <Plan color={Colors.green}>
          <PlanName>Creator</PlanName>
          <Price>$69/mo</Price>
          <PriceLine />
          <PlanDetails>
            <li>Creator StoreFront</li>
            <li>$99 Collection Fee</li>

            <li>Unlimited Products</li>
            <li>10% Bitski Fee</li>
            <li>24hr Support Window</li>
          </PlanDetails>
        </Plan>
        <Plan color={Colors.yellow}>
          <PlanName>Creator pro</PlanName>
          <Price>$199/mo</Price>
          <PriceLine />
          <PlanDetails>
            <li>Creator Storefront</li>
            <li>$49 Collection Fee</li>

            <li>Unlimited Products</li>
            <li>10% Bitski Fee</li>

            <li>8hr Support Window</li>
            <li>Access to Bitski APIs</li>
          </PlanDetails>
        </Plan>
        <Plan color={Colors.purple}>
          <PlanName>agency</PlanName>
          <Price>$1499/m+</Price>
          <PriceLine />
          <PlanDetails>
            <li>Custom Storefront</li>
            <li>5 Staff Members</li>
            <li>3 Free Collections</li>

            <li>Unlimited Products</li>
            <li>10% Bitski Fee</li>
            <li>8hr Support Window</li>
            <li>Access to Bitski APIs</li>
          </PlanDetails>
        </Plan>
      </Plans>
      <CustomPricing>
        <CustomTitle>Custom Pricing</CustomTitle>
        <Paragraph>
          We understand that companies are not one size fits all, and we can
          work with you to customize your offering. Contact us to set up a
          custom plan that suits your business.
        </Paragraph>
        <Button
          color={"white"}
          arrow
          onClick={() => (window.location.href = "mailto:support@bitski.com")}
        >
          contact us
          <Arrow />
        </Button>
        <Coin1>
          <Img fluid={data.coin.childImageSharp.fluid} />
        </Coin1>
        <Coin2>
          <Img fluid={data.coin.childImageSharp.fluid} />
        </Coin2>
      </CustomPricing>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 110.8vw;
  padding: 0 4.7vw;
  position: relative;
  ${media.tablet} {
    padding 0 10.9vw;
    height: 616.9vw;
  }
`;
const Title = styled(Title2)`
  color: white;
  text-align: center;
  ${media.tablet} {
    font-size: 6.8vw;
  }
`;
const Line = styled.hr`
  width: 100%;
  height: 1px;
  background: white;
  border: none;
  margin: 3vw 0 5.2vw;
  ${media.tablet} {
    margin: 4.8vw 0 14.5vw;
  }
`;
const Plans = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.tablet} {
    display: block;
  }
`;
const Plan = styled.div<{ color: string }>`
  background: ${Colors.darkGrey};
  width: 26.7vw;
  height: 43vw;
  border: solid 1px ${(props) => props.color};
  color: ${(props) => props.color};
  position: relative;
  transform-style: preserve-3d;
  padding: 2.8vw 0 0;
  ::before {
    position: absolute;
    content: " ";
    background: ${(props) => props.color};
    width: 100%;
    height: 100%;
    top: 0.6vw;
    left: 0.6vw;
    transform: translateZ(-1px);
    opacity: 0.5;
    transition: 250ms ease;
    border: none;
  }
  :hover {
    ::before {
      opacity: 1;
      top: 1.1vw;
      left: 1.1vw;
    }
  }
  ${media.tablet} {
    width: 100%;
    height: 125.8vw;
    margin-bottom: 14.5vw;
    padding: 8.2vw 0 0;
    ::before {
      top: 1.7vw;
      left: 1.7vw;
    }
  }
`;
const PlanName = styled(Title3)`
  text-align: center;
  text-transform: uppercase;
  color: currentColor;
  margin-bottom: 1vw;
  ${media.tablet} {
    margin-bottom: 3.1vw;
  }
`;
const Price = styled.p`
  font-size: 2vw;
  line-height: 130.5%;
  text-align: center;
  color: white;
  ${media.tablet} {
    font-size: 6vw;
  }
`;
const PriceLine = styled.hr`
  width: 100%;
  border: none;
  height: 1px;
  background: currentColor;
  margin: 2.8vw 0;
  ${media.tablet} {
    margin: 8vw 0;
  }
`;
const PlanDetails = styled.ul`
  width: 20.4vw;
  margin: 0 auto;
  li {
    margin-bottom: 2vw;
    color: white;
    font-size: 1.5vw;
    text-align: center;
    line-height: 130.5%;
  }
  ${media.tablet} {
    width: 60.4vw;
    li {
      font-size: 4.3vw;
      margin-bottom: 5.8vw;
    }
  }
`;

const CustomPricing = styled.div`
  width: 74.79vw;
  height: 21.9vw;
  margin: 11.8vw auto 0;
  border: solid 1px white;
  background: ${Colors.darkGrey};
  position: relative;
  padding: 3.1vw 0 0 6.9vw;
  ${media.tablet} {
    width: 100%;
    margin: 27.8vw 0 0;
    height: 102.9vw;
    padding: 9.2vw 7.2vw;
  }
`;
const CustomTitle = styled(Title)`
  text-align: left;
`;
const Paragraph = styled(Paragraph1)`
  width: 60.9vw;
  color: white;
  margin: 1.2vw 0 3.2vw;
  ${media.tablet} {
    margin: 4.3vw 0 12.1vw;
  }
`;
const Button = styled(BoxOutlineButton)`
  width: 13.3vw;
  background: transparent;

  :focus {
    background: white;
    color: black;
    svg {
      color: black;
    }
  }
  ${media.tablet} {
    width: 46.4vw;
  }
`;
const Arrow = styled(ArrowSVG)`
  color: white;
  width: 1.7vw;
  ${media.tablet} {
    width: 6vw;
  }
`;
const Coin1 = styled.div`
  width: 9.9vw;
  position: absolute;
  top: -3.8vw;
  left: 67vw;
  transform: rotate(-15.5deg);
  ${media.tablet} {
    display: none;
  }
`;
const Coin2 = styled(Coin1)`
  top: 16.9vw;
  left: 27.4vw;
  transform: rotate(30.64deg);
`;
const Grid = styled(GridSVG)`
  position: absolute;
  width: 100vw;
  bottom: 0;
  left: 0;
  ${media.tablet} {
    width: 300vw;
    left: -105vw;
    bottom: -37vw;
  }
`;
export default ChooseAPlan;
